import React from 'react';
import ReactDOM from 'react-dom/client';

import {dynaError} from 'dyna-error';

import {redirectToHttps} from "utils-library/dist/web";

import {reportWebVitals} from "./reportWebVitals";
import "./consoleWarnings";
import "./tools/debug-mode";
import "./core/application/config/sentryConfig";
import "./updateAppHtml";

import "./core/analytics/gaInit";

import {App} from "./core/application/app/App";
import "./index.css";

(window as any)._webApp_mainBundleLoaded = true;

redirectToHttps();

const rootElement = document.getElementById('root');
if (!rootElement) throw  dynaError({message: `Internal error: Could not find the #root element`});
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
