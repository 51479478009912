import {
  HOST,
  ICompanyConfig,
} from "./ICompanyConfig";

import {companyConfigsAdoreGr} from "./adore-gr/companyConfigs";
import {companyConfigsAnelCompany} from "./anel-company/companyConfigs";
import {companyConfigsNetDotFashion} from "./net-fashion/companyConfigs";
import {companyConfigsTheGirlGuns} from "./tgg-com/companyConfigs";

export const appConfigs: Record<HOST, ICompanyConfig> = {
  ...companyConfigsAdoreGr,
  ...companyConfigsAnelCompany,
  ...companyConfigsNetDotFashion,
  ...companyConfigsTheGirlGuns,
};
