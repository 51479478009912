import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
import { EShowDate, EDynaCMSHeadingStyle, } from "./IDynaCMSArticle";
import { getDefaultIDynaCMSFont, } from "./IDynaCMSFont";
import { getDefaultDynaCMSArticleBlockAddress, } from "./IDynaCMSArticleAddress";
export const dynaCMSSettingSensitiveProperties = [
    "googleYoutubeApiKey",
];
export const convertDynaCMSSettingsToBasic = (dynaCMSSettings) => {
    const output = Object.assign({}, dynaCMSSettings);
    dynaCMSSettingSensitiveProperties.forEach(propertyName => delete output[propertyName]);
    return output;
};
export var EDynaCMSShareButtons;
(function (EDynaCMSShareButtons) {
    EDynaCMSShareButtons["NATIVE_SHARE"] = "NATIVE_SHARE";
    EDynaCMSShareButtons["FACEBOOK"] = "FACEBOOK";
    EDynaCMSShareButtons["TWITTER"] = "TWITTER";
    EDynaCMSShareButtons["INSTAGRAM"] = "INSTAGRAM";
    EDynaCMSShareButtons["GOOGLE_PLUS"] = "GOOGLE_PLUS";
    EDynaCMSShareButtons["PINTEREST"] = "PINTEREST";
    EDynaCMSShareButtons["POCKET"] = "POCKET";
    EDynaCMSShareButtons["REDDIT"] = "REDDIT";
    EDynaCMSShareButtons["LINKEDIN"] = "LINKEDIN";
    EDynaCMSShareButtons["WORDPRESS"] = "WORDPRESS";
    EDynaCMSShareButtons["PINBOARD"] = "PINBOARD";
    EDynaCMSShareButtons["EMAIL"] = "EMAIL";
})(EDynaCMSShareButtons || (EDynaCMSShareButtons = {}));
export const getDefaultDynaCMSSettings = () => getDefaultDBEntityDocument({
    countries: [],
    languages: [],
    groups: [],
    articleShareButtons: Object.values(EDynaCMSShareButtons)
        .reduce((acc, shareButton) => {
        acc[shareButton] = true;
        if (shareButton === EDynaCMSShareButtons.INSTAGRAM)
            acc[shareButton] = false;
        return acc;
    }, {}),
    defaultCountryId: '',
    defaultLanguageId: 'en',
    defaultArticleFontFamilyGeneral: getDefaultIDynaCMSFont(),
    defaultArticleFontFamilyHeader: getDefaultIDynaCMSFont(),
    defaultArticleFontFamilyHeaders: getDefaultIDynaCMSFont(),
    defaultArticleShowPublishDateInList: false,
    defaultArticleShowUpdateDateInList: false,
    defaultArticleShowPublishDateInView: true,
    defaultArticleShowUpdateDateInView: false,
    defaultHeadingStyleList: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
    defaultHeadingStyleView: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
    applicationFacebookPage: '',
    applicationInstagramAccount: '',
    applicationTwitterAccount: '',
    applicationPinterestAccount: '',
    applicationYoutubeAccount: '',
    applicationTikTikAccount: '',
    applicationLinkedinCompanyAccount: '',
    googleYoutubeApiKey: '',
});
export const getDefaultDynaCMSSettingsBasic = () => convertDynaCMSSettingsToBasic(getDefaultDynaCMSSettings());
export const getDefaultDynaCMSGroup = () => ({
    id: '',
    coverBlockAddress: getDefaultDynaCMSArticleBlockAddress(),
    label: '',
    pagePath: '',
    includeInSitemap: true,
    hasContent: true,
    articleCreationEnabled: true,
    showPublishDateInList: EShowDate.INHERIT,
    showUpdateDateInList: EShowDate.INHERIT,
    showPublishDateInView: EShowDate.INHERIT,
    showUpdateDateInView: EShowDate.INHERIT,
    internalTags: '',
    notes: '',
});
