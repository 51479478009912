import { encrypt, decrypt, } from "dyna-crypt";
import { JSONParse } from "./JSONParse";
export class Crypt {
    constructor(config) {
        this.config = config;
    }
    encryptText(id) {
        return encodeText(encrypt(id, this.config.key));
    }
    decryptKey(encryptedText) {
        return decrypt(decodeText(encryptedText), this.config.key) || null;
    }
    encryptObject(data) {
        return encodeText(encrypt(JSON.stringify(data), this.config.key));
    }
    decryptData(encryptedData) {
        const decrypted = decrypt(decodeText(encryptedData), this.config.key);
        if (!decrypted)
            return null;
        const { data, error, } = JSONParse({
            serialized: decrypted,
            default: {},
        });
        if (error) {
            console.error(`Crypt.decryptData error 51996785418690: Data decrypted as success but cannot be parsed!`, {
                decrypted,
                error,
            });
            return null;
        }
        return data;
    }
}
const prefixer = 'xtd';
const splitter = 'hx73c';
const encodeText = (sourceText) => prefixer
    + encodeURIComponent(sourceText)
        .replace(/%/g, splitter);
const decodeText = (encodedText) => decodeURIComponent(encodedText
    .slice(prefixer.length)
    .replace(new RegExp(splitter, 'g'), '%'));
