import {
  API_PATH_ApiDynaIntlTranslationsGet,
  IApiDynaIntlTranslationsGetRequest,
  IApiDynaIntlTranslationsGetResponse,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../api/apiFetch";

export const apiDynaIntlTranslationsGet = async (args: IApiDynaIntlTranslationsGetRequest): Promise<IApiDynaIntlTranslationsGetResponse> => {
  return apiFetch.request<IApiDynaIntlTranslationsGetRequest, null, IApiDynaIntlTranslationsGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaIntlTranslationsGet,
    query: args,
  });
};
