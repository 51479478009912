import {ECompany} from "server-app/dist/interfaces";
import {EThemeName} from "ui-components/dist/ThemeProvider";

import {
  EProgressIconType,
  setDefaultProgressIcon,
} from "ui-components/dist/ProgressIcon";

import {
  appVersion,
  appCodeName,
  builtAt,
} from "./appVersion";


import {
  ICompanyConfig,
  EAppMode,
  host,
} from "../../../companies";

import {appConfigs} from "../../../companies/appConfigs";

export {
  EAppMode,
};

export const appConfig: ICompanyConfig =
  appConfigs[host]
  || {
    appName: 'Unknown Company - Host not supported!',
    mode: EAppMode.DEVELOPMENT,
    appVersion,
    appCodeName,
    company: ECompany.UNKNOWN,
    appDescription: 'Unknown Company - Unknown description',
    progressIconType: EProgressIconType.DOUBLE_PULSE,
    companyId: 'unknown-company',
    lightTheme: EThemeName.MUI5_LIGHT,
    darkTheme: EThemeName.MUI5_DARK,
    apiBaseUrl: window.location.origin,
    webBaseUrl: window.location.origin,
    sentryIoDsn: "",
    googleMapsApiKey: "",
    defaultLanguageId: 'en',
    builtAt,
    workerActive: false,
    signInPageImage: undefined,
    termsOfUseArticles: [],
    aboutArticles: [],
    contactArticles: [],
  } as ICompanyConfig;

setDefaultProgressIcon(appConfig.progressIconType);
